import { Item } from "./AuthContext";
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from "react";

interface CartItemProps {
    item: Item
    updateQuantity: (itemId: number, count: number) => void
}

export const CartItem = ({ item, updateQuantity }: CartItemProps) => {
    const [isUpdatingQuantity, setIsUpdatingQuantity] = useState(false)
    const update = async (count: number) => {
        setIsUpdatingQuantity(true)
        try {
            await updateQuantity(item.id, count)
        } finally {
            setIsUpdatingQuantity(false)
        }
    }
    return (
    <ListItem>
        <ListItemText primary={item.name} secondary={item.description} />
        <div>
            <Typography align="right">${item.price}</Typography>
            <div style={{width: 110}}>
                <Typography align="right">
                <Fab color="secondary" aria-label={item.count === 1 ? "delete" : "subtract"} disabled={isUpdatingQuantity} onClick={() => update(item.count - 1)} size="small">
                    {item.count === 1 ? <DeleteIcon/> : <RemoveIcon />}
                </Fab>
                <span style={{paddingRight: '4px', paddingLeft: '4px'}}>{item.count}</span>
                <Fab color="secondary" aria-label="add" disabled={isUpdatingQuantity} onClick={() => update(item.count + 1)}  size="small">
                    <AddIcon />
                </Fab>
                </Typography>
            </div>
        </div>
    </ListItem>
    )
}