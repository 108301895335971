import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../AuthContext";

export const useUnauthenticatedRedirect = () => {
    const navigate = useNavigate();
    const { isLoggedIn } = useContext(AuthContext);
  
    useEffect(() => {
      if (!isLoggedIn) {
        navigate("/");
      }
    }, [isLoggedIn, navigate]);
}