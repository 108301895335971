import Paper from "@mui/material/Paper";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../AuthContext";
import CheckoutForm from "../CheckoutForm";
import { useUnauthenticatedRedirect } from "../hooks/useUnauthenticatedRedirect";
import { Footer } from "../Footer";
import { CircularProgress } from "@mui/material";

const stripePromise = loadStripe(
  "pk_test_51K3koaCI9wWyKDPpR4As6K2VSUtt67O8KHN4qit2WxkzAkCTVh6L5cMAbUlzQM7ohylhTOv31XlJFZsIGrdNwxwQ00wXJhAdBF"
);

export const Payment = () => {
  useUnauthenticatedRedirect();
  const [clientSecret, setClientSecret] = useState("");
  const [orderTotal, setOrderTotal] = useState("");
  const { createOrderIntent } = useContext(AuthContext);

  useEffect(() => {
    const getIntent = async () => {
      const secret = await createOrderIntent();
      setClientSecret(secret.clientSecret);
      setOrderTotal(secret.orderTotal)
    };
    getIntent();
  }, [createOrderIntent]);

  return (
    <>
      <Paper style={{ padding: "8px" }}>
        {clientSecret ? (
          <Elements
            options={{
              clientSecret,
              appearance: {
                theme: "stripe",
              },
            }}
            stripe={stripePromise}
          >
            <CheckoutForm orderTotal={orderTotal}/>
          </Elements>
        ) : (
          <div style={{ height: "420px", width: "292px" }}>
            <CircularProgress style={{ marginTop: "200px" }} />
          </div>
        )}
      </Paper>
      <Footer />
    </>
  );
};
