import React, { useContext, useState } from "react";
import AuthContext from "../AuthContext";
import "./Login.css";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import { Footer } from "../Footer";
import { useNavigate } from "react-router-dom";

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [isSigningUp, setIsSigningUp] = useState(false);
  const { signUp } = useContext(AuthContext);
  const submit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (password1 === password2) {
      setIsSigningUp(true);
      try {
        await signUp(email, password1);
        navigate("/cart");
      } finally {
        setIsSigningUp(false);
      }
    }
  };

  const felidStyle = {
    paddingBottom: 8,
    width: "100%",
  };

  return (
    <>
      <Paper>
        <form className="Login-form" onSubmit={submit}>
          <TextField
            style={felidStyle}
            placeholder="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <br />
          <TextField
            style={felidStyle}
            placeholder="password"
            type="password"
            value={password1}
            onChange={(e) => setPassword1(e.target.value)}
          />
          <br />
          <TextField
            style={felidStyle}
            placeholder="password again"
            type="password"
            value={password2}
            onChange={(e) => setPassword2(e.target.value)}
          />
          <br />
          <Button disabled={isSigningUp} variant="contained" type="submit">
            Submit
          </Button>
          <div>
            <Button
              onClick={() => {
                navigate("/");
              }}
            >
              Log In Instead
            </Button>
          </div>
        </form>
      </Paper>
      <Footer />
    </>
  );
}

export default Login;
