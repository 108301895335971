import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { Footer } from "../Footer";
import { useUnauthenticatedRedirect } from "../hooks/useUnauthenticatedRedirect";

export const Confirm = () => {
  useUnauthenticatedRedirect();
  return (
    <>
      <Paper>
        <p>Confirm coming</p>
        <Button variant="contained" size="large">
          Order
        </Button>
      </Paper>
      <Footer />
    </>
  );
};
