import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Address } from "./pages/Address";
import Cart from "./pages/Cart";
import { Confirm } from "./pages/Confirm";
import Login from "./pages/Login";
import { Payment } from "./pages/Payment";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import SignUp from "./pages/SignUp";

const router = createBrowserRouter([
  {
    path: "address",
    element: <Address />,
  },
  {
    path: "cart",
    element: <Cart />,
  },
  {
    path: "confirm",
    element: <Confirm />,
  },
  {
    path: "payment",
    element: <Payment />,
  },
  {
    path: "privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "sign-up",
    element: <SignUp />,
  },
  {
    path: "/",
    element: <Login />,
  },
]);

export const Routes = () => <RouterProvider router={router} />;
