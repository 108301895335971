import React, { useContext, useState } from "react";
import AuthContext from "../AuthContext";
import "./Login.css";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Footer } from "../Footer";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const { login } = useContext(AuthContext);
  const submit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoggingIn(true);
    try {
      await login(email, password);
      navigate("./cart");
    } finally {
      setIsLoggingIn(false);
    }
  };
  return (
    <>
      <Paper>
        <Typography>
          Experience products in virtual reality before you buy.
          <Typography>
            <a href="https://www.oculus.com/experiences/quest/5039601149493631/">
              Download the app.
            </a>
          </Typography>
        </Typography>
        <iframe
          width="300"
          height="200"
          src="https://www.youtube.com/embed/vDI0-0nPjK0"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
        <div>
        <img src="https://storage.googleapis.com/shopyvers-front-end/gameplay3.PNG"
        style={{width: 'calc(50% - 12px)', padding: '8px 4px 8px 8px'}}
        alt="gameplay 1"
        />
        <img src="https://storage.googleapis.com/shopyvers-front-end/gameplay2.PNG"
        style={{width: 'calc(50% - 12px)', padding: '8px 8px 8px 4px'}}
        alt="gameplay 2"
        />
        <img src="https://storage.googleapis.com/shopyvers-front-end/gameplay1.PNG"
        alt="gameplay 3"
        style={{width: 'calc(100% - 18px)', padding: '0 8px'}}
        />
        </div>
        <form className="Login-form" onSubmit={submit}>
          <TextField
            style={{
              width: "100%",
            }}
            placeholder="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <br></br>
          <TextField
            style={{
              paddingTop: 8,
              paddingBottom: 8,
              width: "100%",
            }}
            placeholder="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <br></br>
          <Button
            disabled={isLoggingIn}
            variant="contained"
            type="submit"
            style={{ width: "100%" }}
          >
            Login
          </Button>
        </form>
        <Button
          onClick={() => {
            navigate("./sign-up");
          }}
        >
          Sign Up Instead
        </Button>
      </Paper>
      <Footer />
      <Typography>
        Have questions or feedback? Email the admin at orrin@shopyvers.com
      </Typography>
    </>
  );
}

export default Login;
