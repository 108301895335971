import "./App.css";
import { Routes } from "./Routes";

function App() {
  return (
    <div className="App">
      <div className="App-content">
        <div style={{ maxWidth: 320 }}>
          <img
            src="https://storage.googleapis.com/shopyvers-front-end/Hero.png"
            style={{ width: "100%" }}
            alt="Shopyvers Logo banner"
          />
          <Routes />
        </div>
      </div>
    </div>
  );
}

export default App;
