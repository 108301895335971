
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import { useContext, useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import AuthContext from '../AuthContext'
import { useUnauthenticatedRedirect } from '../hooks/useUnauthenticatedRedirect'
import { useNavigate } from 'react-router-dom'
import { Footer } from '../Footer'

export const Address = () => {
    useUnauthenticatedRedirect()
    const navigate = useNavigate()
    const { getAddresses, updateAddress, createAddress } = useContext(AuthContext);
    const [isInitialLoading, setIsInitialLoading] = useState(true)
    const [isUpdatingAddress, setIsUpdatingAddress] = useState(false)
    const [isAddressValid, setIsAddressValid] = useState(false)
    const [fullName, setFullName] = useState('')
    const [phone, setPhone] = useState('')
    const [line1, setLine1] = useState('')
    const [line2, setLine2] = useState('')
    const [city, setCity] = useState('')
    const [zipCode, setZipCode] = useState('')
    const [state, setState] = useState('')
    const [id, setId] = useState<number | null>(null)

    const submit = async () => {
        if (fullName && phone && line1 && city && zipCode && state) {
            try {
                setIsUpdatingAddress(true)
                if (id) {
                    await updateAddress({
                        name: fullName,
                        phone,
                        line1,
                        line2,
                        city,
                        zip: zipCode,
                        state,
                        id,
                    })
                }
                else {
                    await createAddress({
                        name: fullName,
                        phone,
                        line1,
                        line2,
                        city,
                        zip: zipCode,
                        state
                    })
                }
                navigate('/payment')
            } finally {
                setIsUpdatingAddress(false)
            }
        }
    }

    useEffect(() => {
        const getStartAddress = async () => {
            setIsInitialLoading(true)
            try {
                const addresses = await getAddresses()
                const address = addresses[0]
                if (address) {
                    setFullName(address.name)
                    setPhone(address.phone)
                    setLine1(address.line1)
                    setLine2(address.line2)
                    setCity(address.city)
                    setZipCode(address.zip)
                    setState(address.state)
                    setId(address.id)
                }
            } finally {
                setIsInitialLoading(false)
            }
        }
        getStartAddress()
    }, [getAddresses])
    useEffect(() => {
        if (fullName && phone && line1 && city && zipCode && state) {
            setIsAddressValid(true)
        }
        else {
            setIsAddressValid(false)
        }
    }, [fullName, phone, line1, city, zipCode, state])

    const separatorStyle = {
        paddingTop: 8,
        paddingLeft: 8,
        paddingRight: 8,
    }

    const cityStateZipStyle = {
        width: 'calc(33% - 5.333px)'
    }

    const stateStyle = {
        paddingLeft: 8,
        paddingRight: 8,
    }

    const fieldStyle = {
        width: '100%'
    }

    const buttonDivStyle = {
        paddingBottom: 8
    }
    return (
        <>
            <Paper>
                <div style={separatorStyle}><TextField style={fieldStyle} disabled={isInitialLoading} variant="outlined" placeholder='Full name, first and last' value={fullName} onChange={(e) => setFullName(e.target.value)} /></div>
                <div style={separatorStyle}><TextField style={fieldStyle} disabled={isInitialLoading} variant="outlined" placeholder='Phone number' value={phone} onChange={(e) => setPhone(e.target.value)} /></div>
                <div style={separatorStyle}><TextField style={fieldStyle} disabled={isInitialLoading} variant="outlined" placeholder='Address line 1' value={line1} onChange={(e) => setLine1(e.target.value)} /></div>
                <div style={separatorStyle}><TextField style={fieldStyle} disabled={isInitialLoading} variant="outlined" placeholder='Address line 2' value={line2} onChange={(e) => setLine2(e.target.value)} /></div>
                <div style={separatorStyle}>
                    <TextField style={cityStateZipStyle} disabled={isInitialLoading} variant="outlined" placeholder='City' value={city} onChange={(e) => setCity(e.target.value)} />
                    <TextField style={{ ...stateStyle, ...cityStateZipStyle }} disabled={isInitialLoading} variant="outlined" placeholder='State' value={state} onChange={(e) => setState(e.target.value)} />
                    <TextField style={cityStateZipStyle} disabled={isInitialLoading} variant="outlined" placeholder='Zip code' value={zipCode} onChange={(e) => setZipCode(e.target.value)} />
                </div>
                <div style={{ ...separatorStyle, ...buttonDivStyle }}><Button style={fieldStyle} disabled={isInitialLoading || isUpdatingAddress || !isAddressValid} variant="contained" size="large" onClick={submit}>Next</Button></div>
            </Paper>
            <Footer />
        </>
    )
}