import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const Footer = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Button onClick={() => navigate("/privacy-policy")} size="small">
        Privacy Policy
      </Button>
    </div>
  );
};
