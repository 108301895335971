import React, { useContext, useState } from 'react';
import AuthContext from './AuthContext';
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

export function PairHeadset() {
  const [pairCode, setPairCode] = useState('')
  const [isPairing, setIsPairing] = useState(false)
  const [isSuccessShowing, setIsSuccessShowing] = useState(false)
  const { pairHeadset } = useContext(AuthContext)
  const showSuccess = () => {
    setIsSuccessShowing(true)
    setTimeout(() => {
      setIsSuccessShowing(false)
    }, 5000)
  }
  const submit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setIsPairing(true)
    showSuccess()
    try {
      await pairHeadset(pairCode)
      showSuccess()
    }
    finally {
      setIsPairing(false)
    }
    setPairCode('')
  }
  if (isSuccessShowing) {
    return (
      <Typography variant="h5" style={{ height: 72, paddingTop: 16 }}>
        Successfully paired headset
      </Typography>
    )
  }
  return (
    <form className="Login-form" onSubmit={submit}>
      <Button style={{ height: 56, marginRight: 8 }} disabled={isPairing} variant="contained" type="submit" size="large">Pair</Button>
      <TextField placeholder="Pair code" type="text" value={pairCode} onChange={(e) => setPairCode(e.target.value)} />
    </form>
  );
}
