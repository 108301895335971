import Paper from "@mui/material/Paper";

export const PrivacyPolicy = () => {
  return (
    <Paper style={{padding: '16px'}}>
      <h1>Privacy Policy</h1>
      <h2>What User Data do we process?</h2>
      <p>
        We collect the bare minimum to make our application work. Namely: Log in
        info, user info (eg name, address, email) if you're purchasing something
        here, and cart info (what's in it)
      </p>
      <h2>How do we processing that data?</h2>
      <p>
        All data is passed through our servers and stored in our databases. When
        purchasing online we send payment info to stripe
      </p>
      <h2>For what purposes do we processing that data?</h2>
      <p>
        To make the application run as expected. We are not calculating
        analytics or using your data for targeting. We are simply using your
        info to track your cart and process your orders
      </p>
      <h2>How can you request for your data to be deleted?</h2>
      <p>Send the admin an email: orrin@shopyvers.com</p>
    </Paper>
  );
};
