import "./Login.css";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import { PairHeadset } from "../PairHeadset";
import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import AuthContext, { Item } from "../AuthContext";
import { CartItem } from "../CartItem";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import Button from "@mui/material/Button";
import { useUnauthenticatedRedirect } from "../hooks/useUnauthenticatedRedirect";
import { useNavigate } from "react-router-dom";
import { Footer } from "../Footer";

let refreshTimeoutId: NodeJS.Timeout;

function Cart() {
  useUnauthenticatedRedirect();
  const navigate = useNavigate();
  const [itemsInCart, setItemsInCart] = useState<Item[]>([]);
  const { getItemsInCart, updateItemQuantity } = useContext(AuthContext);
  const fetchItemsInCart = useCallback(async () => {
    if (refreshTimeoutId) {
      clearTimeout(refreshTimeoutId);
    }
    try {
      const items = await getItemsInCart();
      setItemsInCart(items);
    } finally {
      refreshTimeoutId = setTimeout(fetchItemsInCart, 5000);
    }
  }, [getItemsInCart]);
  const updateQuantity = async (itemId: number, count: number) => {
    await updateItemQuantity(itemId, count);
    await fetchItemsInCart();
  };
  useEffect(() => {
    fetchItemsInCart();
    return () => clearTimeout(refreshTimeoutId);
  }, [fetchItemsInCart]);

  const total = itemsInCart.reduce(
    (total, item) => parseFloat(item.price) * item.count + total,
    0
  );

  return (
    <>
      <Paper>
        <PairHeadset />
        <List>
          {itemsInCart.map((item) => (
            <Fragment key={item.id}>
              <CartItem item={item} updateQuantity={updateQuantity} />
              <Divider component="li" />
            </Fragment>
          ))}
          <ListItem>
            <Button
              variant="contained"
              size="large"
              onClick={() => navigate("/address")}
              disabled={total === 0}
            >
              Checkout
            </Button>
            <Typography align="right" style={{ padding: "8px" }}>
              Total: ${total.toFixed(2)}
            </Typography>
          </ListItem>
        </List>
      </Paper>
      <Footer />
    </>
  );
}

export default Cart;
