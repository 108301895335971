import { useCallback, useState, ReactNode } from "react";
import AuthContext, { Address, Item } from "./AuthContext";
import axios from "axios";

const baseUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5001/shopyvers/us-central1/"
    : "https://us-central1-shopyvers.cloudfunctions.net/";

const AuthContextProvider = ({ children }: { children: ReactNode }) => {
  const [authToken, setAuthTokenLocal] = useState(
    localStorage.getItem("authToken") || ""
  );
  const [userId, setUserIdLocal] = useState(
    localStorage.getItem("userId") || ""
  );

  const setAuthToken = useCallback((authToken: string) => {
    setAuthTokenLocal(authToken);
    localStorage.setItem("authToken", authToken);
  }, []);

  const setUserId = useCallback((userId: string) => {
    setUserIdLocal(userId);
    localStorage.setItem("userId", userId);
  }, []);

  const createOrderIntent = async () => {
    const response = await axios.post(
      `${baseUrl}order/intent`,
      {},
      {
        headers: { Authorization: `Bearer ${authToken}` },
      }
    );
    return response.data.data;
  };

  const login = async (email: string, password: string) => {
    const response = await axios.post(`${baseUrl}user/auth-token`, {
      email,
      password,
    });
    setAuthToken(response.data?.meta?.authToken || "");
    setUserId(response.data?.data?.id || "");
  };

  const signUp = useCallback(
    async (email, password) => {
      const response = await axios.post(`${baseUrl}user`, {
        email,
        password,
      });
      setAuthToken(response.data?.meta?.authToken || "");
      setUserId(response.data?.data?.id || "");
    },
    [setAuthToken, setUserId]
  );

  const pairHeadset = useCallback(
    async (pairCode) => {
      await axios.post(
        `${baseUrl}user/${userId}/headset`,
        {
          pairCode,
        },
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
    },
    [userId, authToken]
  );

  const getItemsInCart = async () => {
    const response = await axios.get(`${baseUrl}cart/items`, {
      headers: { Authorization: `Bearer ${authToken}` },
    });
    return response.data.data.map(
      ({ count, item }: { count: number; item: Omit<Item, "count"> }) => ({
        count,
        ...item,
      })
    );
  };

  const updateItemQuantity = async (itemId: number, count: number) => {
    if (count < 1) {
      await axios.delete(`${baseUrl}cart/${itemId}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
    } else {
      await axios.post(
        `${baseUrl}cart/${itemId}`,
        {
          count,
        },
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
    }
  };

  const getAddresses = async () => {
    const response = await axios.get<{ data: Address[] }>(
      `${baseUrl}user/${userId}/address`,
      {
        headers: { Authorization: `Bearer ${authToken}` },
      }
    );
    return response.data.data;
  };

  const createAddress = async (address: Omit<Address, "id">) => {
    await axios.post(
      `${baseUrl}address`,
      { ...address },
      {
        headers: { Authorization: `Bearer ${authToken}` },
      }
    );
  };

  const updateAddress = async (address: Address) => {
    await axios.patch(
      `${baseUrl}address/${address.id}`,
      { ...address },
      {
        headers: { Authorization: `Bearer ${authToken}` },
      }
    );
  };

  return (
    <AuthContext.Provider
      value={{
        createOrderIntent,
        login,
        signUp,
        pairHeadset,
        isLoggedIn: !!authToken,
        getItemsInCart,
        updateItemQuantity,
        getAddresses,
        createAddress,
        updateAddress,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
