import React from "react";

export type Item = { count: number, id:number, name: string, description: string, price: string }
export interface Address {
    name: string
    phone: string
    line1: string
    line2: string
    city: string 
    state: string 
    zip: string,
    id: number
  }
const AuthContext = React.createContext({
    createOrderIntent: async () => ({clientSecret: '', orderTotal: ''}),
    login: async (email: string, password: string) => { },
    signUp: async (email: string, password: string) => { },
    pairHeadset: async (pairCode: string) => { },
    getItemsInCart: async (): Promise<Item[]> => [],
    isLoggedIn: false,
    updateItemQuantity: async (itemId: number, count:number) => {},
    getAddresses: async (): Promise<Address[]> => ([]),
    createAddress: async (address: Omit<Address, 'id'>) => {}, 
    updateAddress: async (address: Address) => {}
});
export default AuthContext